
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































.cta-jobs-inner {
  @extend %border-radius-small;

  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: $card-shadow;

  @include mq(l) {
    flex-direction: row;
  }

  @include mq(xl) {
    margin: 0 col(0.5, 10);
  }

  @include mq(xxl) {
    margin: 0 col(1, 10);
  }
}

.cta-jobs__picture {
  position: relative;
  order: -1;
  width: 100%;
  aspect-ratio: 3/2;

  img {
    @include image-fit;

    border-radius: 0.8rem 0.8rem 0 0;
  }

  @include mq(l) {
    width: 45rem;
    aspect-ratio: unset;

    img {
      border-radius: 0.8rem 0 0 0.8rem;
    }
  }
}

.cta-jobs__content {
  @extend %border-radius-small;

  padding: var(--spacing-m);
  background: $c-white;

  @include mq(l) {
    flex: 1;
  }
}

.cta-jobs__content__title {
  font-size: 3rem;

  @include mq(xl) {
    font-size: 4rem;
  }
}

.cta-jobs__content__text {
  margin: var(--spacing-s) 0 var(--spacing-m);

  ::v-deep strong {
    @extend %ff-alt;

    color: $c-mustard;
    font-size: 2.5rem;
  }

  @include mq(l) {
    margin: $spacing 0;
  }
}

.cta-jobs__content__link {
  &::after {
    @include get-all-space;

    z-index: 1;
    content: '';
  }
}
